import { createRoot } from 'react-dom/client'
import React from 'react'
import { isProduction, trackingSentryAPIKey, sentryEnvironment } from '@glow/common/utils/env'
import { initializeSiteAnalytics, initializeSiteMetrics } from '@glow/instrumentation'
import App from './App'
import '@glow/common/utils/atPolyfill'
import './index.css'

const ENABLE_ANALYTICS_FOR_THIS_APP = false
if (isProduction() && ENABLE_ANALYTICS_FOR_THIS_APP) {
  initializeSiteAnalytics()
}

const apiKey = trackingSentryAPIKey()
if (apiKey) {
  initializeSiteMetrics(apiKey, sentryEnvironment(), 0.001)
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
