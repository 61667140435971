import en from './en'
import nb from './nb'
import sv from './sv'
import da from './da'
import fi from './fi'
import fr from './fr'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { defaultLanguage, userLanguage } from './language'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      nb: { translation: nb },
      da: { translation: da },
      sv: { translation: sv },
      fi: { translation: fi },
      fr: { translation: fr }
    },
    lng: userLanguage(),
    fallbackLng: defaultLanguage,
    compatibilityJSON: 'v3',
    debug: false,
    returnNull: false,
    interpolation: {
      escapeValue: false, // not needed for react
      formatSeparator: ',',
      skipOnVariables: false
    },
    react: {
      useSuspense: false
    }
  })
  .then()

document.documentElement.lang = i18n.language
