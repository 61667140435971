import { isPosten } from '@glow/common/utils/env'

const supportedLanguages = ['en', 'nb', 'sv', 'fi', 'da', 'fr']
export const defaultLanguage = supportedLanguages[0]

export const userLanguage = (): string => {
  if (isPosten()) {
    // The Posten pages should always use Norwegian.
    return 'nb'
  }

  if (window.location.search.includes('lng=')) {
    // Allow overriding language through the "lng" query string, for testing.
    const parsedQueryString = new URLSearchParams(window.location.search)
    return parsedQueryString.get('lng') || defaultLanguage
  }

  // Detect the language from the browser and format it (e.g. "nb-NO" to "nb").
  const browserLanguage = navigator.language?.toLowerCase().slice(0, 2)

  return browserLanguage && supportedLanguages.includes(browserLanguage) ? browserLanguage : defaultLanguage
}

// Map the detected language code used on the frontend to
// the language code which should be sent to the backend.
export const backendLanguage = (language = userLanguage()) => {
  // The frontend calls Danish "da" while the backend uses "dk".
  if (language === 'da') {
    return 'dk'
  }

  return language
}
