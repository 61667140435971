import React, { useContext, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { ThemeContext } from '../common/contexts/ThemeContext'
import { Theme } from '../common/utils/theme'
import { useLocation } from 'react-router-dom'
import i18next from 'i18next'

export function MetaTags() {
  const { theme } = useContext(ThemeContext)
  const image = previewImage(theme)
  const appName = applicationName(theme)
  const location = useLocation()

  const title = useMemo(() => {
    const deliveredBy = pageTitle(theme)

    let title = i18next.t('menu.shipmentTracking')
    if (location.pathname.includes('info')) {
      title = i18next.t('menu.aboutShipment')
    } else if (location.pathname.includes('events')) {
      title = i18next.t('menu.shipmentEvents')
    }
    return `${title} | ${i18next.t('logisticsProvider.deliveredBy')} ${deliveredBy}`
  }, [location.pathname])

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="apple-mobile-web-app-title" content={appName} />
        <meta name="application-name" content={appName} />

        <meta property="og:image" content={image} />
        <meta content="1200" property="og:image:width" />
        <meta content="630" property="og:image:height" />

        <meta content={title} property="og:title" />
        <meta content={title} property="og:site_name" />
        <meta content="website" property="og:type" />

        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Favicons />
    </>
  )
}

function Favicons() {
  const { theme, themeV2, isLoading } = useContext(ThemeContext)

  if (isLoading) {
    return <></>
  }

  if (theme === 'ikea' || theme == 'ikea-dk') {
    return (
      <Helmet>
        <link rel="shortcut icon" href={'/track/ikea_favicon.ico'} />
      </Helmet>
    )
  }

  if (themeV2?.images?.favicon) {
    return (
      <Helmet>
        <link rel="shortcut icon" href={themeV2?.images?.favicon} />
      </Helmet>
    )
  }

  if (theme === 'bring') {
    return (
      <Helmet>
        <link rel="shortcut icon" href="/track/bring_favicon.ico" />
      </Helmet>
    )
  }

  if (theme === 'posten') {
    return (
      <Helmet>
        <link rel="shortcut icon" href="/track/posten_favicon.ico" />
      </Helmet>
    )
  }

  if (theme === 'amoi') {
    return (
      <Helmet>
        <link rel="shortcut icon" href={'/track/amoi_favicon.ico'} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={'/track/favicon-glow-512x512.png'} sizes="512x512" />
      <link rel="icon" type="image/png" href={'/track/favicon-glow-192x192.png'} sizes="192x192" />
      <link rel="icon" type="image/png" href={'/track/favicon-glow-32x32.png'} sizes="32x32" />
      <link rel="icon" type="image/png" href={'/track/favicon-glow-16x16.png'} sizes="16x16" />
      <link rel="shortcut icon" href={'/track/favicon.ico'} />
    </Helmet>
  )
}

function pageTitle(theme: Theme) {
  switch (theme) {
    case 'bring':
      return 'Bring'
    case 'posten':
      return 'Posten'
    case 'ikea':
    case 'ikea-dk':
      return 'IKEA'
    case 'amoi':
      return 'Amoi'
    case 'glow':
    default:
      return 'Glow'
  }
}

function previewImage(theme: Theme) {
  switch (theme) {
    case 'ikea-dk':
      return '/track/609841a6-1785-4387-a719-dd72e2713012.jpg'
    case 'ikea':
      return '/track/8434482e-978d-11eb-a8b3-0242ac130003.jpg'
    case 'bring':
      return '/track/919ef502-b937-44c9-897f-95678a821d42.jpg'
    case 'posten':
      return '/track/818be263-32ec-4193-99d2-f109defa1892.jpg'
    case 'glow':
    default:
      return '/track/preview-recipient-tracking-page-default.jpeg'
  }
}

function applicationName(theme: Theme) {
  switch (theme) {
    case 'posten':
      return 'Posten - Følg leveransen din'
    case 'bring':
    case 'ikea':
    case 'ikea-dk':
    case 'glow':
    default:
      return 'Track your delivery'
  }
}
