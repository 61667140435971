export const consignmentStandard = [
  {
    originalArrivalTime: null,
    adjustedArrivalTime: null,
    adjustedArrivalTimeFrom: null,
    adjustedArrivalTimeTo: null,
    plannedDeliveryDate: null,
    orderedDeliveryDate: null,
    deliveryTimeEarliest: '2022-10-26T11:00',
    tzDeliveryTimeEarliest: 'Europe/Stockholm',
    deliveryTimeLatest: '2022-10-26T16:00',
    tzDeliveryTimeLatest: 'Europe/Stockholm',
    deliveryLat: 59.2821906,
    deliveryLng: 17.9701478,
    pickupName: 'COMPANY NAME',
    trackingType: null,
    departmentAlystraId: null,
    serviceCode: null,
    deliveryName: 'DELIVERY NAME',
    deliveryAddress: 'Street 12',
    deliveryZipCode: '123 45',
    deliveryZipArea: 'Area',
    deliveryCountry: 'Country',
    deliveryPhone: null,
    deliveryEmail: null,
    deliveryAttempts: 0,
    slotId: null,
    state: null,
    id: 1443770,
    vasCode: null,
    externalId: '93afcbd2-569e-4a05-bc1b-241f2b34b4b8',
    shipmentId: '987654321987654321',
    customerRef: '',
    source: null,
    eta: null,
    etaSmsSent: false,
    pickupEtaSmsSent: false,
    eventId: 2096211,
    eventData: null,
    eventEventTime: '2022-10-26T07:50:00Z',
    eventType: 'collected',
    lng: 17.9801,
    lat: 59.3821,
    customerAlystraId: null,
    customerId: null,
    flexDeliveryTextId: null,
    flexDeliveryMessage: null,
    flexDeliveryOrdered: false,
    flexDeliveryTime: null,
    gateCode: null,
    halfTimeWindow: null,
    customerServiceLevel: null,
    departmentServiceLevel: null,
    fuelType: null,
    serviceLevel: 'standard',
    vehicleType: null,
    customerUniqueDeviation: null,
    deliveryTrackingSmsSent: false,
    isDelayed: false,
    isDigitalIdentityVerified: false,
    recentlyFailedDigitalIdentificationAttempts: 0,
    producedInCorrectOrder: null,
    vasCodes: null,
    isPremium: false,
    allowFlexDelivery: true,
    allowDigitalIdentityVerification: false,
    serviceName: '',
    serviceDescription: '',
    valueAddedServices: [],
    vasDescriptions: [],
    fuelCategory: null,
    canTrackDelivery: true,
    timeBookingSupported: false,
    reportDamageRecipientSupported: false,
    timeBookingAllowed: false,
    requestPhoneNumber: false,
    plannedDeliveryTime: { deliveryEarliest: '2022-10-26T17:00', deliveryLatest: '2022-10-26T21:00' },
    isHomeDeliveryParcelNorway: false,
    isHomeDelivery: false,
    theme: null,
    stopsLeft: 0,
    themeV2: { links: null, colors: null },
    estimatedDelivery: {
      eta: null,
      earliest: '2022-08-18T14:00:00Z',
      latest: '2022-08-18T15:00:00Z'
    },
    locationTime: null
  },
  {
    originalArrivalTime: null,
    adjustedArrivalTime: null,
    adjustedArrivalTimeFrom: null,
    adjustedArrivalTimeTo: null,
    plannedDeliveryDate: null,
    orderedDeliveryDate: null,
    deliveryTimeEarliest: '2022-10-26T11:00',
    tzDeliveryTimeEarliest: 'Europe/Stockholm',
    deliveryTimeLatest: '2022-10-26T16:00',
    tzDeliveryTimeLatest: 'Europe/Stockholm',
    deliveryLat: 59.2821906,
    deliveryLng: 17.9701478,
    pickupName: 'COMPANY NAME',
    trackingType: null,
    departmentAlystraId: null,
    serviceCode: null,
    deliveryName: 'DELIVERY NAME',
    deliveryAddress: 'Street 12',
    deliveryZipCode: '123 45',
    deliveryZipArea: 'Area',
    deliveryCountry: 'Country',
    deliveryPhone: null,
    deliveryEmail: null,
    deliveryAttempts: 0,
    slotId: null,
    state: null,
    id: 1443770,
    vasCode: null,
    externalId: '93afcbd2-569e-4a05-bc1b-241f2b34b4b8',
    shipmentId: '987654321987654321',
    customerRef: '',
    source: null,
    eta: null,
    etaSmsSent: false,
    pickupEtaSmsSent: false,
    eventId: 2096210,
    eventData: { unitId: 1386, userId: 2872, unitName: 'BIL 13', userName: 'BIL 13', deviation: '7-10' },
    eventEventTime: '2022-10-26T07:50:00Z',
    eventType: 'deviated',
    lng: 17.9801,
    lat: 59.3821,
    customerAlystraId: null,
    customerId: null,
    flexDeliveryTextId: null,
    flexDeliveryMessage: null,
    flexDeliveryOrdered: false,
    flexDeliveryTime: null,
    gateCode: null,
    halfTimeWindow: null,
    customerServiceLevel: null,
    departmentServiceLevel: null,
    fuelType: null,
    serviceLevel: 'standard',
    vehicleType: null,
    customerUniqueDeviation: null,
    deliveryTrackingSmsSent: false,
    isDelayed: false,
    isDigitalIdentityVerified: false,
    recentlyFailedDigitalIdentificationAttempts: 0,
    producedInCorrectOrder: null,
    vasCodes: null,
    isPremium: false,
    allowFlexDelivery: true,
    allowDigitalIdentityVerification: false,
    serviceName: '',
    serviceDescription: '',
    valueAddedServices: [],
    vasDescriptions: [],
    fuelCategory: null,
    canTrackDelivery: true,
    timeBookingSupported: false,
    reportDamageRecipientSupported: false,
    timeBookingAllowed: false,
    requestPhoneNumber: false,
    plannedDeliveryTime: { deliveryEarliest: '2022-10-26T17:00', deliveryLatest: '2022-10-26T21:00' },
    isHomeDeliveryParcelNorway: false,
    isHomeDelivery: false,
    theme: null,
    stopsLeft: 0,
    themeV2: { links: null, colors: null },
    estimatedDelivery: {
      eta: null,
      earliest: '2022-08-18T14:00:00Z',
      latest: '2022-08-18T15:00:00Z'
    },
    locationTime: null
  },
  {
    originalArrivalTime: null,
    adjustedArrivalTime: null,
    adjustedArrivalTimeFrom: null,
    adjustedArrivalTimeTo: null,
    plannedDeliveryDate: null,
    orderedDeliveryDate: null,
    deliveryTimeEarliest: '2022-10-26T11:00',
    tzDeliveryTimeEarliest: 'Europe/Stockholm',
    deliveryTimeLatest: '2022-10-26T16:00',
    tzDeliveryTimeLatest: 'Europe/Stockholm',
    deliveryLat: 59.2821906,
    deliveryLng: 17.9701478,
    pickupName: 'COMPANY NAME',
    trackingType: null,
    departmentAlystraId: null,
    serviceCode: null,
    deliveryName: 'DELIVERY NAME',
    deliveryAddress: 'Street 12',
    deliveryZipCode: '123 45',
    deliveryZipArea: 'Area',
    deliveryCountry: 'Country',
    deliveryPhone: null,
    deliveryEmail: null,
    deliveryAttempts: 0,
    slotId: null,
    state: null,
    id: 1443770,
    vasCode: null,
    externalId: '93afcbd2-569e-4a05-bc1b-241f2b34b4b8',
    shipmentId: '987654321987654321',
    customerRef: '',
    source: null,
    eta: null,
    etaSmsSent: false,
    pickupEtaSmsSent: false,
    eventId: 2094545,
    eventData: null,
    eventEventTime: '2022-10-25T08:34:44.638851Z',
    eventType: 'created',
    lng: null,
    lat: null,
    customerAlystraId: null,
    customerId: null,
    flexDeliveryTextId: null,
    flexDeliveryMessage: null,
    flexDeliveryOrdered: false,
    flexDeliveryTime: null,
    gateCode: null,
    halfTimeWindow: null,
    customerServiceLevel: null,
    departmentServiceLevel: null,
    fuelType: null,
    serviceLevel: 'standard',
    vehicleType: null,
    customerUniqueDeviation: null,
    deliveryTrackingSmsSent: false,
    isDelayed: false,
    isDigitalIdentityVerified: false,
    recentlyFailedDigitalIdentificationAttempts: 0,
    producedInCorrectOrder: null,
    vasCodes: null,
    isPremium: false,
    allowFlexDelivery: true,
    allowDigitalIdentityVerification: false,
    serviceName: '',
    serviceDescription: '',
    valueAddedServices: [],
    vasDescriptions: [],
    fuelCategory: null,
    canTrackDelivery: true,
    timeBookingSupported: false,
    reportDamageRecipientSupported: false,
    timeBookingAllowed: false,
    requestPhoneNumber: false,
    plannedDeliveryTime: { deliveryEarliest: '2022-10-26T17:00', deliveryLatest: '2022-10-26T21:00' },
    isHomeDeliveryParcelNorway: false,
    isHomeDelivery: false,
    theme: null,
    stopsLeft: 0,
    themeV2: { links: null, colors: null },
    estimatedDelivery: {
      eta: null,
      earliest: '2022-08-18T14:00:00Z',
      latest: '2022-08-18T15:00:00Z'
    },
    locationTime: null
  }
]
