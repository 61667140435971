import formatDistanceWithOptions from 'date-fns/fp/formatDistanceWithOptions'
import formatRelativeWithOptions from 'date-fns/fp/formatRelativeWithOptions'
import formatWithOptions from 'date-fns/fp/formatWithOptions'
import isToday from 'date-fns/isToday'
import isTomorrow from 'date-fns/isTomorrow'
import isYesterday from 'date-fns/isYesterday'
import da from 'date-fns/locale/da'
import enGB from 'date-fns/locale/en-GB'
import fi from 'date-fns/locale/fi'
import fr from 'date-fns/locale/fr'
import nb from 'date-fns/locale/nb'
import sv from 'date-fns/locale/sv'
import parseISO from 'date-fns/parseISO'
import i18next from 'i18next'
import { userLanguage } from '../../i18n/language'

const locales = new Map<string, Locale>([
  ['en', enGB],
  ['sv', sv],
  ['fi', fi],
  ['da', da],
  ['nb', nb],
  ['fr', fr]
])

export const distanceFromNow = (time: string | undefined, locale: undefined | Locale = getLocale()): string => {
  if (typeof time === 'string' && time !== '') {
    return distanceFromNowWithLocale(time, locale)
  } else return ''
}

export const formatDateRelativeWithTime = (time: string | Date | undefined): string => {
  if (!time) return ''

  let t
  if (typeof time === 'string' && time !== '') {
    t = parseISO(time)
  } else if (time === '') return ''
  else t = time

  return formatRelativeWithOptions({ locale: getLocale(), weekStartsOn: 1 })(Date.now())(t)
}

export const toJson = (date: Date) => `${formatDate(date, 'yyyy-MM-dd')}T${format24HourTime(date)}`

export const formatDate = (time: string | Date | undefined, format?: string): string => {
  if (!time) return ''

  let t
  if (typeof time === 'string' && time !== '') {
    t = parseISO(time)
  } else if (time === '') return ''
  else t = time

  const formatOrDefault = format ? format : 'dd.MM.yyyy'

  return formatWithOptions({ locale: getLocale(), weekStartsOn: 1 })(formatOrDefault)(t)
}

export const format24HourTime = (date?: Date, format?: string): string => {
  return date ? formatWithOptions({ locale: getLocale() })(format ? format : 'HH:mm')(date) : ''
}

const distanceFromNowWithLocale = (time: string, locale: Locale) =>
  formatDistanceWithOptions({ locale: locale })(Date.now())(parseISO(time))

export const getLocale = () => locales.get(userLanguage()) || enGB

export function formatDateRelativeWithLocale(date: string | Date | null | undefined, format?: string) {
  if (!date) return ''
  const dt = typeof date === 'string' ? parseISO(date) : date

  if (isToday(dt)) return i18next.t('date.today')
  else if (isYesterday(dt)) return i18next.t('date.yesterday')
  else if (isTomorrow(dt)) return i18next.t('date.tomorrow')
  else if (dt) return formatDate(date, format)
  else {
    console.error('formatDateRelativeWithLocale - could not handle it', date, dt)
    return ''
  }
}

const getFullDate = (): number[] => {
  const now = new Date()
  const year = now.getFullYear()
  const monthIndex = now.getMonth()
  const day = now.getDate()

  return [year, monthIndex, day]
}

export const isWithinPeriod = (year: number, monthIndex: number, startDay: number, endDay: number): boolean => {
  const [yearNow, monthIndexNow, dayNow] = getFullDate()

  return yearNow === year && monthIndexNow === monthIndex && dayNow >= startDay && dayNow <= endDay
}

export const todayIsBeforeOrEqualToDate = (date: Date) => new Date() <= date

export const isTodayWithinPeriod = (startDate: Date, endDate: Date) => {
  const today = new Date()
  return startDate <= today && today <= endDate
}
