import { SiteMetricsErrorBoundary } from '@glow/instrumentation'
import { ButtonBase, LayoutMain, PageHeading } from '@glow/ui-components'
import React, { Fragment, ReactElement, ReactNode, useEffect } from 'react'
import { getPageReloadedWithin10Sec, setPageReloadedWithin10Sec } from './util'

interface Props {
  title?: string
  children: ReactNode
}

function MainBox(props: Props): ReactElement {
  return (
    <section className="bg-theme-surface rounded shadow-dark p-4 sm:p-8">
      {props.title && <h1 className="font-semibold text-base mb-4 sm:mb-8">{props.title}</h1>}
      {props.children}
    </section>
  )
}

export function ErrorBoundary({
  isRunningTests,
  children,
  title,
  buttonText
}: {
  children: ReactNode
  isRunningTests: boolean
  buttonText: string
  title: string
}): ReactElement {
  if (isRunningTests) {
    // Let errors thrown during testing bubble all the way up
    // so that Jest shows errors in the terminal as expected.
    return <Fragment>{children}</Fragment>
  }

  return (
    <SiteMetricsErrorBoundary
      fallback={(props) => <ErrorBoundaryFallback {...props} title={title} buttonText={buttonText} />}
    >
      {children}
    </SiteMetricsErrorBoundary>
  )
}

export function ErrorBoundaryFallback(props: {
  error: unknown
  componentStack: string | null
  buttonText: string
  title: string
  resetError: () => void
}): ReactElement {
  useEffect(() => {
    if (
      props.error instanceof Error &&
      props.error.message.includes('Failed to fetch dynamically imported module') &&
      !getPageReloadedWithin10Sec(localStorage)
    ) {
      setPageReloadedWithin10Sec(localStorage)
      window.location.reload()
    } else if (
      props.error instanceof Error &&
      props.error.message.includes('Unable to preload CSS') &&
      !getPageReloadedWithin10Sec(localStorage)
    ) {
      setPageReloadedWithin10Sec(localStorage)
      window.location.reload()
    }
  }, [props.error])
  return (
    <LayoutMain title={<PageHeading>{props.title}</PageHeading>}>
      <div className="grid gap-4 sm:gap-8">
        <MainBox title={JSON.stringify(props.error)}>
          <div>
            <ButtonBase variant="primary" onClick={() => window.location.reload()}>
              {props.buttonText}
            </ButtonBase>
          </div>
        </MainBox>
        {props.componentStack && (
          <pre
            className="p-4
  sm:p-8
  overflow-x-auto
  rounded
  text-xs
  border
  border-gray-300
  text-emphasis-medium"
          >
            {props.componentStack.trim()}
          </pre>
        )}
      </div>
    </LayoutMain>
  )
}
