import { Theme } from '@glow/common'
import { ThemeColors } from '@glow/entity-types'
import chroma from 'chroma-js'

export const AARegularTextContrast = 4.5
export const AANonTextContrast = 3
export const indexOfPrimary500 = 5
export const indexOfPrimary400 = 4

export const getButtonTextColor = (colors: ThemeColors, theme: Theme) => {
  const color = theme === 'posten' || theme === 'bring' ? colors.primary400 : colors.primary500
  if (color.length !== 7) {
    return color
  }
  const whiteTextContrast = chroma.contrast(color, 'white')
  return whiteTextContrast > AARegularTextContrast ? 'white' : 'black'
}

export const getA11yColor = (
  colors: string[],
  startPosition: number,
  colorContrastMeasure: number,
  theme: Theme
): string => {
  const color = colors.at(startPosition)
  if (color?.length !== 7 || theme !== 'custom-theme') {
    return color ?? 'black'
  }
  if (chroma.contrast(color, 'white') > colorContrastMeasure) {
    return color
  } else {
    const nextColorIndex = startPosition + 1
    if (nextColorIndex < colors.length) {
      return getA11yColor(colors, nextColorIndex, colorContrastMeasure, theme)
    }
  }
  return 'black'
}
