import { getApiPath } from './api'

type CommandType =
  | 'ADD_OR_UPDATE_HOUSING_INFORMATION'
  | 'ADD_DELIVERY_PHONE_NUMBER'
  | 'ORDER_OR_UPDATE_FLEX_DELIVERY'
  | 'UPLOAD_RECIPIENT_ATTACHMENT'
  | 'UPDATE_DELIVERY_TIME'
  | 'ORDER_DELIVERY_TIME'
  | 'PURCHASE_DELIVERY_TIME_WINDOW'
  | 'OPEN_BOOKING'
  | 'CANCEL_FLEX_DELIVERY'
  | 'UPLOAD_RECIPIENT_DAMAGE_ATTACHMENTS'
  | 'REPORT_MISSING_ARTICLE'
  | 'ADD_DELIVERY_PHONE_NUMBER_BY_SHIPMENT_ID'
  | 'PURCHASE_SERVICE'
  | 'SET_PARCEL_LOCKER'
  | 'CANCEL_PARCEL_LOCKER_DELIVERY'
  | 'TRACKING_PAGE_LOADED'
  | 'CANCEL_DELIVERY_TIME'
  | 'SAVE_RECYCLE_GOODS_DETAILS'

const apiPath = getApiPath()

const sendCommandInternal = (
  commandName: string,
  url: string,
  payload: unknown,
  mockRequest?: boolean
): Promise<any> => {
  if (mockRequest) {
    return new Promise<void>((resolve) => resolve())
  }
  const fetchData = async ({}, {}) => {
    const response = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(payload)
    })

    if (response.ok) {
      return response.json()
    } else if (response.status === 400 || response.status === 404) {
      return response.json().then((errors) => Promise.reject(errors))
    } else throw new Error(response.statusText)
  }

  return fetchData({}, {}).catch((error) => Promise.reject(error))
}

const fullUrl = (commandName: string, trackingId: string): string => {
  return `${apiPath}/tracking/${trackingId}/${commandName}`
}

export const sendCommand = (
  commandName: CommandType,
  trackingId: string,
  payload: unknown,
  mockRequest?: boolean
): Promise<any> => sendCommandInternal(commandName, fullUrl(commandName, trackingId), payload, mockRequest)

export const sendOpenCommand = async (commandName: CommandType, payload: unknown): Promise<any> =>
  await sendCommandInternal(commandName, `${apiPath}/opencommand/${commandName}`, payload)

export const sendCommandRaw = (commandName: CommandType, uri: string, data: FormData): Promise<any> => {
  const fetchData = async ({}, {}) => {
    const response = await fetch(`${apiPath}/${uri}`, {
      credentials: 'include',
      method: 'POST',
      body: data
    })

    if (!response.ok) throw new Error(response.statusText)
    return response.json()
  }

  return fetchData({}, {}).catch((error) => Promise.reject(error))
}
