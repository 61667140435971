import classNames from 'classnames'
import i18next from 'i18next'
import React, { useContext, useEffect } from 'react'
import general404 from '../../assets/icons/default-404.svg'
import { MainContainer } from '../../trackdelivery/components/MainContainer'
import { DefaultLogo } from '../components/Logo'
import { ThemeContext } from '../contexts/ThemeContext'
import { Theme, isBringOrPostenTheme, isPostenTheme, themeClassName } from '../utils/theme'
import './PageNotFound.scss'

const PageNotFound = () => {
  const { theme } = useContext(ThemeContext)
  useEffect(() => {
    // Log to Sentry
    console.warn('Page not found', window.location.href)
  }, [])

  return (
    <div className={themeClassName(theme)}>
      <MainContainer className={classNames('full-height', isBringOrPostenTheme(theme) && '!bg-white')}>
        <div className="page-not-found-wrapper">
          <figure className="bring-logo align-center !ml-6 sm:!ml-[60px]">
            <DefaultLogo theme={theme} />
          </figure>
          {isBringOrPostenTheme(theme) ? <BringOrPostenContent theme={theme} /> : <GeneralContent />}
        </div>
      </MainContainer>
    </div>
  )
}

const BringOrPostenContent = ({ theme }: { theme: Theme }) => (
  <div className="align-center mt-16 flex flex-col gap-16">
    <div className={classNames('text-5xl', isPostenTheme(theme) ? 'font-bold' : 'font-light')}>
      {i18next.t('pageNotFound.title')}
    </div>
    <div className="text-xl">{i18next.t('pageNotFound.reloadPage')}</div>
  </div>
)

const GeneralContent = () => (
  <>
    <ShowNotFoundImage />
    <div className="align-center">
      <h1 className="font-bold text-5xl">{i18next.t('pageNotFound.title')}</h1>
      <p className="mt-4">{i18next.t('pageNotFound.message')}</p>
    </div>
  </>
)

const ShowNotFoundImage = () => (
  <figure>
    <img className="not-found-image" src={general404} alt={i18next.t('imageAlt.pageNotFound')} />
  </figure>
)

export default PageNotFound
