import platform from 'platform'
export type Browser = 'Chrome' | 'Firefox' | 'Safari' | 'Edge'

export function getBrowserInfo(): { name: Browser; version?: number } | undefined {
  const userAgent = navigator.userAgent
  let browserName: Browser | undefined = undefined
  const majorVersion = platform.version?.split('.')[0]
  const majorVersionNumber = majorVersion ? Number.parseInt(majorVersion) : NaN

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge'
  }
  return browserName
    ? { name: browserName, version: !isNaN(majorVersionNumber) ? majorVersionNumber : undefined }
    : undefined
}

export const doesAnyPageMatchCurrentPathname = (urls: string[], currentPathname: string) => {
  const currentPathNameSplit = currentPathname.split('/').filter((x) => x !== '')

  return urls.some((url) => {
    const urlPathName = url.replace(window.location.origin, '')
    const urlPathnameSplit = urlPathName.split('/').filter((x) => x !== '')
    if (urlPathnameSplit.length < currentPathNameSplit.length && urlPathnameSplit.at(-1) !== '*') {
      return false
    }
    if (urlPathnameSplit.length > currentPathNameSplit.length) {
      return false
    }

    for (let i = 0; i < urlPathnameSplit.length; i++) {
      if (urlPathnameSplit[i] === '*') {
        continue
      } else if (urlPathnameSplit[i] === currentPathNameSplit[i]) {
        continue
      }
      return false
    }
    return true
  })
}
