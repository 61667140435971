const time10Sec = 10000
const localStorageKey = 'PRELOAD_FAILED'

export function setPageReloadedWithin10Sec(storage: Storage) {
  const item = {
    value: 'true',
    expiry: new Date().getTime() + time10Sec
  }
  storage.setItem(localStorageKey, JSON.stringify(item))
}

export function getPageReloadedWithin10Sec(storage: Storage) {
  const itemString = storage.getItem(localStorageKey)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    storage.removeItem(localStorageKey)
    return null
  }

  return item.value
}
