const da = {
  common: {
    unknown: 'Ukendt',
    shipmentId: 'Forsendelsesnummer',
    senderRef: 'Afsenders reference',
    phone: 'Telefon:',
    yes: 'Ja',
    no: 'Nej',
    cancel: 'Annuller',
    somethingWentWrongTryAgain: 'Noget gik galt. Prøv igen senere.',
    courier: 'Bud',
    unsupportedBrowser: 'Opdater din browserversion for at få adgang til alle funktioner',
    updatedInformation: 'Opdateret information',
    thanks: 'Tak!',
    edit: 'Rediger',
    confirmation: 'Bekræftelse',
    required: 'påkrævet'
  },
  application: {
    loading: 'Indlæses, vent venligst.'
  },
  feedbackBox: {
    title: 'Hvad synes du',
    description:
      'Vi gennemfører i øjeblikket en test, hvor du som modtager kan spore dine forsendelser i realtid, når de er ude til levering. Vi er interesserede i at høre din mening, og  <0>du kan give os feedback via dette link</0>.'
  },
  skipToMainContent: 'Gå til hovedindhold',
  countries: { sweden: 'Sverige', norway: 'Norge', denmark: 'Danmark' },
  pageNotFound: {
    title: 'Side ikke fundet',
    message: 'Vi kan ikke finde siden du leder efter',
    reloadPage: 'Prøv at genindlæse siden'
  },
  menu: {
    shipmentTracking: 'Hvor er min forsendelse?',
    aboutShipment: 'Om min forsendelse',
    shipmentEvents: 'Hændelser'
  },
  logisticsProvider: { deliveredBy: 'Leveret af' },
  deliveredShipment: { goodsDelivered: 'Din forsendelse er leveret!' },
  deviatedShipment: {
    goodsNotDelivered: 'Forsendelsen blev ikke leveret',
    lastEvent: 'Sidste hændelse {{time}}',
    nextStep: {
      headline: 'Hvad sker nu?',
      newAttempt:
        'Vi forsøger at levere pakken hjemme hos dig næste hverdag. Du får en ny besked når den er klar til at bilve kørt ud.',
      returnToSender: 'I følge instruktionerne fra afsender vil pakken blive retuneret til afsender.',
      pickupPoint:
        'Pakken vil blive leveret i din lokale Pakkeshop den næste hverdag. Du får en ny besked når den er klar til afhentning.',
      reachOut: 'Vi vil kontakte dig for at aftale nyt leveringsforsøg'
    }
  },
  shipmentTracking: {
    airExpress: {
      collected:
        'Pakkerne fra {{company}} er blevet afhentet i {{pickupArea}} og vil blive sendt med fly til {{deliveryArea}}.',
      collectedAtDeliveryLeg: 'Pakkerne fra {{company}} er blevet afhentet i {{arrivalAirportName}}.',
      collectedAtFlightLeg:
        'Pakkerne fra {{company}} er blevet accepteret i terminalen i {{departureAirportName}}, og vil blive sendt med fly til {{deliveryArea}}.',
      collectedAtFlightLegWithOnlyFlightLeg:
        'Pakkerne fra {{company}} er blevet accepteret i {{departureAirportName}}. Pakkerne vil blive sendt med fly til {{arrivalAirportName}}, hvor de vil blive afhentet af modtageren.',
      collectedAtFlightLegWithPickup:
        'Pakkerne fra {{company}} vil blive sendt med fly til {{arrivalAirportName}}, hvor de vil blive afhentet af modtageren.',
      collectedAtPickupLegWithPickup:
        'Pakkerne fra {{company}} er blevet afhentet i {{pickupArea}}, og vil blive sendt med fly til {{arrivalAirportName}}, hvor de vil blive afhentet af modtageren.'
    },
    map: 'Kort',
    estimatedDeliveryTime: 'Beregnet leveringstid',
    deliveryTimeChanged: 'Din estimerede leveringstid er blevet ændret.',
    numberOfStopsLeft_one: '{{count}} stop inden dig',
    numberOfStopsLeft_other: '{{count}} stop inden dig',
    manyStopsLeft: 'Mere end {{many}} stop inden dig',
    nextStop: 'Du er næste stop!'
  },
  aboutShipment: {
    delayMessageH2Message: ' Hvis din pakke bliver forsinket, så vil vi forsøge at levere igen næste hverdag.',
    plannedDeliveryTimeHeader: 'Planlagt levering af din forsendelse fra {{customerName}}',
    header: 'Om din levering fra {{customerName}}',
    deliveryTime: 'Leveringstidspunkt',
    timeWindow: 'mellem {{timeWindow}}',
    deliveryHeader: 'Leveringsadresse og kontaktinfo'
  },
  shipmentEvents: { header: 'Sidste nyt om din forsendelse fra {{customerName}}' },
  imageAlt: {
    calendarClockIcon: 'Kalenderklokke',
    questionMarkIcon: 'Spørgsmålstegn',
    checkIcon: 'Flueben',
    thumbsUp: 'Tommelfinger op',
    sadComputer: 'Trist computer',
    goodsOutsideDoor: 'Vare uden for dør',
    image: 'Billede'
  },
  error: {
    cannotLoadMap: 'Kortet kan ikke loades lige nu, beklager.',
    invalidDeliveryDate: 'Det valgte tidsrum er ikke længere tilgængeligt. Genindlæs siden og forsøg igen.',
    title: 'Noget gik galt!',
    errorClickToReload: 'Klik her for at genindlæse siden',
    phoneNumber: 'Ugyldigt telefonnummer. Gyldigt f.eks.: +45 12345678.',
    phoneNumberPrefix: 'Du skal angive en landekode, f.eks. +45 ...'
  },
  event: {
    parcels: 'kolli',
    outOf: 'af',
    deviationHeadline: {
      'H-12': 'Vi kunne ikke levere din forsendelse',
      'H-10': 'Vi kunne ikke finde din adresse',
      'H-13': 'Forsendelsen vil blive returneret',
      'V-41-K': 'Forsendelsen er desværre forsinket',
      'V-DB-AB': 'Forsendelsen er desværre forsinket',
      'H-BL-A': 'Vi kunne ikke nå frem til det aftalte leveringssted',
      'H-11-Z': 'Vi kunne ikke finde din adresse',
      'VI-16-X': 'Det lykkedes ikke at levere din forsendelse'
    },
    deviation: {
      'H-12': 'Desværre mødte vi ingen derhjemme, der kunne modtage den',
      'H-11': 'Der er forsøgt levering af pakken, men modtageren blev ikke fundet',
      'H-10': 'Vi kunne ikke levere din forsendelse, fordi vi manglede oplysninger om din adresse',
      'H-13': 'Fordi du ikke ønskede at modtage den',
      '7-10': 'Varer kunne ikke afhentes på grund af ufuldstændig adresse',
      '7-12': 'Afsenderen var ikke tilgængelig. Varerne er ikke afhentet',
      '7-82': 'Varerne var ikke klar til afhentning',
      'V-41-K': 'Den vil blive fremsendt hurtigst muligt',
      'V-DB-AB': 'Den vil blive fremsendt hurtigst muligt',
      'H-07-R': 'Mislykket social kontrol',
      'H-07-Z': 'Mislykket social kontrol',
      'H-08-Z': 'Mislykket alderskontrol',
      'H-09-Z': 'Mislykket ID kontrol',
      'VI-16-X':
        'I dag gik ikke alt som det skulle, og vi nåede derfor ikke at levere din forsendelse som aftalt. Vi beklager forsinkelsen',
      'H-BL-A': 'Derfor var vi desværre ikke i stand til at levere din forsendelse, hvor du anmodede os om',
      'H-11-Z': 'En typisk årsag til dette er, at der manglede navn eller nummer ved døren'
    },
    message: {
      created: 'Oprettet',
      delivered: 'leveret',
      collected: 'opsamlet',
      deviated: 'Afveget',
      'delivery-time-estimated': 'Opdateret leveringstid',
      'delivery-time-purchased': 'Ny leveringstid købt',
      'delivered-manually-overridden': 'leveret',
      'deviated-manually-overridden': 'Afveget',
      'collected-manually-overridden': 'Opsamlet',
      'flex-delivery-ordered': 'Forenklet levering',
      flexDeliveryOrderedDescription: 'Forenklet levering bestilt af modtager',
      'flex-delivery-ordered-by-sender': 'Forenklet levering bestilt af afsender',
      'flex-delivery-changed': 'Forenklet levering ændret',
      'apartment-information-updated': 'Lejlighedsoplysninger opdateret',
      'service-upgrade-purchased': 'Tjeneste opdateret',
      serviceUpgradePurchasedDescription: 'Leveringen blev opdateret fra kantsten til indbæring'
    }
  },
  date: { date: 'Dato', today: 'I dag', tomorrow: 'I morgen', yesterday: 'I går' },
  serviceUpsales: {
    heading: 'Vil du have varen båret ind?',
    selectDateTime: 'Vælg dag og tid for indbæring',
    confirm: 'Til betaling',
    curbsideServiceInfo: 'Med indbæring får du leveret varen til et valgfrit sted i hjemmet.',
    orderService: 'Vælg tid for indbæring (299kr.)',
    agreeToTerms: 'Ved at bestille, godkender du <0>vilkårene for indbæring</0>.',
    noAvailableDeliveryTimesError: 'Noget gik galt under indlæsning af tidsslots. Genindlæs venligst siden.',
    termsLink: 'Se <0>vilkår for indbæring</0>'
  },
  housingInfo: {
    housingTypeTitle: 'Bor du i lejlighed eller hus?',
    apartment: 'Lejlighed',
    house: 'Hus',
    selectConfirmationApartment:
      'Tak fordi du hjælper os med at lette leveringen. Sørg venligst for, at <strong>dit navn</strong> er synligt på døren/postkassen.',
    selectConfirmationHouse:
      'Tak fordi du hjælper os med at lette leveringen. Sørg venligst for, at <strong>dit husnummer</strong> er synligt på døren/postkassen.',
    apartmentInformation: 'Information om lejligheden',
    gateCodeOrNameLabel: 'Navn eller nummer på dørtelefonen',
    floorNumber: 'Etagenummer',
    selectOption: 'Vælg mulighed',
    elevatorIsAvailable: 'Ja, der er elevator tilgængelig',
    elevatorNotAvailable: 'Nej, der er ikke elevator tilgængelig',
    submitError: 'Indtast venligst et etagenummer',
    submit: 'Send',
    submitChange: 'Send Ændringer',
    confirmationText: 'Dette vil hjælpe til at lette leveringen.',
    confirmationHeader: 'Tak for opdateringen af din lejlighedsinformation.'
  },
  flex: {
    header: 'Skal vi stille forsendelsen udenfor din dør?',
    termsApproval: 'Ved at vælge ja, godkender du vores <1>vilkår</1>.',
    privacyPolicy: 'Du finder vores <1>fortrolighedspolitik</1> her.',
    deliverySpotHeader: 'Hvor skal vi stille forsendelsen?',
    deliverySpotDefaultOption: 'Vælg lokation',
    deliverySpotTextHeader: 'Meddelelse til chaufføren (max 50 tegn)',
    deliverySpotError: 'Vælg hvor vi skal stille forsendelsen',
    confirmationHeader: 'Tak for opdateringen af dine leveringsoplysninger.',
    confirmationText:
      'Som aftalt stiller vi din forsendelse {{location}}, og giver dig besked, når leveringen er fuldført. Chaufføren tager muligvis et billede ved levering.',
    infoText:
      'Som aftalt stiller vi din forsendelse {{location}}. Vi giver dig besked, når leveringen er fuldført. Chaufføren tager muligvis et billede ved levering.',
    notRingDoorBellText: 'Buddet vil ikke ringe på dørklokken.',
    submitChanges: 'Send ændringer',
    submit: 'Send',
    terms: {
      header: 'Betingelser og vilkår',
      description: 'Levering udenfor døren - Sådan fungerer det:',
      details1:
        'Som modtager vælger du hvor vi skal placere pakken og du er ansvarlig for at sikre, at det er et passende placering. Husk, at leveringsstedet skal være både sikkert og i læ for vind og vejr.',
      details2: 'Du vil modtage en SMS der bekræfter at pakken er leveret.',
      details3: 'Efter levering er du, som modtager, ansvarlig for skader og tab.'
    },
    flexDeliveryCancelledInfo: 'Vi vil levere personligt.'
  },
  digitalIdentity: {
    cta: {
      verify: 'Bekræft din identitet',
      submit: 'Send og underskriv',
      retry: 'Prøv at bekræfte igen',
      info: {
        header: 'Vil du have en mere smidig levering?',
        se: 'For at gøre leveringen nemmere kan du identificere dig med BankID inden leveringstidspunktet',
        no: 'For at gøre leveringen nemmere kan du identificere dig med BankID inden leveringstidspunktet',
        dk: 'For at gøre leveringen nemmere kan du identificere dig med MitID inden leveringstidspunktet'
      },
      flex: {
        header: 'Identifikation påkrævet',
        se: 'Du skal identificere dig selv, før vi kan placere leveringen udenfor. Brug knappen ovenfor til at bekræfte din identitet med BankID.',
        no: 'Du skal identificere dig selv, før vi kan placere leveringen udenfor. Brug knappen ovenfor til at bekræfte din identitet med BankID.',
        dk: 'Du skal identificere dig selv, før vi kan placere leveringen udenfor. Brug knappen ovenfor til at bekræfte din identitet med MitID.'
      },
      button: { se: 'Skriv under med BankID', no: 'Skriv under med BankID', dk: 'Skriv under med MitID' }
    },
    verificationError: {
      header: 'Bekræftelse mislykkedes',
      message:
        'Du kan altid modtage din forsendelse personligt ved at vise et gyldigt identifikationsdokument, når chaufføren ankommer, eller du kan prøve at gennemføre verifikationen igen'
    },
    verificationSuccess: {
      header: 'Din identitet er verificeret',
      saveCodeHeader: 'Identitetsbekræftelse blev gennemført!',
      saveCodeText:
        'Gem denne kode og tag den med, når du får pakken, eller send den til den, der henter den. Du får en sms, når kureren er uden for leveringsadressen. Denne kode sendes også som en sms.',
      nextPageVisit: 'Din bekræftelseskode er sendt til dig på sms, giv koden til chaufføren ved levering.'
    },
    visibleDamageNotice:
      'Sørg for at tjekke varerne for synlige skader, inden du godkender leveringen. Synlige skader skal registreres af føreren.'
  },
  damageInfo: {
    reportDamage: 'Registrer skade',
    submit: 'Send',
    unfinishedReport: 'Unfinished Report',
    reportSubmittedPara1:
      'Tak fordi du tog dig tid til at indberette en uoverensstemmelse i forbindelse med din ordre. IKEA kontakter dig inden for 24 timer.',
    reportSubmittedPara2:
      'Tryk på "Færdig" for at gå tilbage til startsiden, eller for at foretage endnu en indberetning.'
  },
  plannedDelivery: {
    headline: {
      delivery: 'Planlagt leveringstid for din levering fra {{customer}}',
      pickup: 'Din forsendelse fra {{customer}} er nu klar til afhentning. Planlagt tid for afhentning',
      return: 'Planlagt tid for afhentning af din returforsendelse til {{customer}}'
    },
    deliveryTimeInfo: 'Når vi begynder ruten, vil du få en mere præcis estimeret leveringstid inden for en time.',
    notAGoodTime: 'Passer tiden ikke?',
    changeTime: 'Ændre dato og tid',
    newAttempt: 'Er du ikke hjemme til at tage imod pakken, forsøger vi igen næste hverdag',
    pickupPoint: 'Er du ikke hjemme til at tage imod pakken, leverer vi den til dit nærmeste afhentningssted',
    returnToSender: 'Er du ikke hjemme til at tage imod pakken, vil pakken blive returneret til afsender',
    delay: { headline: 'Din leverance er forsinket.', text: ' Vi forsøger at levere den til dig næste hverdag' },
    notAvailable: {
      hd: {
        headline: 'Noget ser ud til at være gået galt!',
        text: 'Ring til vores Kundeservice for at aftale ny leveringstid. Se telefonnumre nedenfor.'
      },
      nonHd: {
        headline: 'Vi har desværre ikke et leveringsestimat endnu',
        text: ', men du får en melding når pakken er lastet på bil for levering og du kan tjekke denne side igen ved en senere anledning for mere information.'
      }
    }
  },
  timeBooking: {
    confirm: 'Bekræftet',
    header: 'Vælg dato og tid',
    deliveryTime: { delivery: 'Leveringstid', pickup: 'Afhentningstid', return: 'Afhentningstid' },
    submit: {
      delivery: 'Bekræft leveringstid',
      pickup: 'Bekræftet afhentningstid',
      return: 'Bekræftet afhentningstid'
    },
    confirmation: {
      header: 'Bekræftelse',
      ready: 'Nu er du klar!',
      details: {
        delivery: 'Læn dig tilbage, så tager vi os af resten. Vi er hos dig:',
        pickup: 'Læn dig tilbage, så tager vi os af resten. Du er velkommen hos os:',
        return: 'Læn dig tilbage, så tager vi os af resten. Vi er hos dig:'
      },
      edit: 'Ændre'
    }
  },
  bookingNotPossible: {
    header: 'Booking ikke muligt',
    details: {
      delivery:
        'Beklager, det er ikke muligt at booke en leveringstid online lige nu. Prøv igen senere, eller kontakt vores kundeservice på et af nedenstående telefonnumre',
      pickup:
        'Det er ikke muligt at booke afhentningstid via nettet lige nu. Prøv igen lidt senere eller ring til vores kontakt center på nedenstående nummer.',
      return:
        'Det er ikke muligt at booke afhentningstid via nettet lige nu. Prøv igen lidt senere eller ring til vores kontakt center på nedenstående nummer.'
    },
    'details-saas': {
      delivery: 'Desværre er det ikke muligt at booke en leveringstid online lige nu. Prøv venligst igen senere.',
      pickup: 'Afhentningstid kan ikke bookes online lige nu. Prøv venligst igen om et øjeblik.',
      return: 'Afhentningstid kan ikke bookes online lige nu. Prøv venligst igen om et øjeblik.'
    }
  },
  paymentFailed: {
    header: 'Betaling mislykkedes',
    details: 'Prøv venligst igen senere, eller ring til vores kundeservice på et af telefonnumrene nedenfor.',
    detailsSaas: 'Desværre kunne vi ikke gennemføre betalingen. Prøv venligst igen senere.',
    goBack: 'Gå tilbage til sporingssiden'
  },
  contact: { questions: 'Hvis du har spørgsmål', callUs: 'kan du ringe til vores kundeservice.' },
  pickupTracking: { estimatedPickupTime: 'Estimeret afhentningstid', goodsPickedUp: 'Godset er blevet afhentet!' },
  environmentFriendly: {
    fossilFreeDelivery1: 'Din levering er ',
    fossilFreeDelivery2: 'fossilfri.',
    zeroEmissionDelivery: 'Din levering køres ',
    bicyclePreposition: 'med ',
    carPreposition: 'med ',
    electric: 'elbil.',
    bicycle: 'cykel.',
    hydrogen: 'hydrogenbil.',
    bring: {
      electric: '100% elektrisk',
      bicycle: '100% muskelkraft',
      hydrogen: '100% brint',
      fossilFreeDelivery: '100% fossilfrit brændstof',
      naturally: 'naturligvis'
    }
  },
  ikeaDeliveryNote: {
    doYouNeedADeliveryNote: 'Skal du bruge en følgeseddel fra IKEA?',
    noProblemDownloadItHere: 'Intet problem! Du kan downloade den her.',
    downloadDeliveryNote: 'Download følgeseddel',
    noProblemViewItHere: 'Ingen problem! Du kan se og udskrive den her.',
    viewDeliveryNote: 'Se følgeseddel'
  },
  deliveryNote: {
    title: 'Følgeseddel',
    sender: 'Afsender',
    vatNumber: 'Momsnummer',
    orderNumber: 'Ordernummer',
    receiver: 'Modtager',
    phone: 'Telefon',
    shipmentId: 'Forsendelsesnummer',
    articles: 'Artikler',
    orderDate: 'Bestillingsdato',
    deliveryDate: 'Leveringsdato',
    deliveryTime: 'Leveringstid',
    printedDate: 'Udskriftsdato',
    receiversReference: 'Modtagers reference',
    receiversReferenceNote: 'Brug dette nummer ved al kontakt med os',
    articleNumber: 'Nummer',
    articleName: 'Navn',
    articlePrice: 'Enhedspris',
    articleQuantity: 'Antal',
    numberOfPackages: 'Antal pakker',
    totalWeight: 'Samlet vægt',
    totalVolume: 'Samlet volumen',
    print: 'Udskriv følgeseddel'
  },
  requestPhoneNumber: {
    header: 'SMS notifikationer',
    help: 'Tilføj dit nummer for at få leveringsnotifikationer på SMS',
    label: 'Dit mobilnummer',
    save: 'Gem nummer',
    done: 'Tak! Du vil nu modtage leveringsnotifikationer på SMS'
  },
  vasDescription: { header: 'Om din levering' },
  christmas: {
    thankYou: 'Tusinde tak',
    wishRegistered: 'Dit ønske er registreret på ønskesedlen'
  },
  altText: { previewImage: 'Forhåndsvisning af billedet' },
  purchaseReceipt: {
    purchaseOfTimeWindowHeading: 'Dit nye leveringstidspunkt er bestilt og bekræftet',
    serviceUpgradeHeading: 'Opdatering af levering er bestilt og bekræftet',
    receipt: 'Kvittering',
    organisationNumber: 'Virksomhedsnummer: 556546-4939',
    orderNumber: 'Ordrenummer',
    date: 'Dato',
    time: 'Tidspunkt',
    service: 'Tjeneste',
    purchaseOfTimeWindow: 'Leveringsvindue',
    serviceUpgrade: 'Levering med indbæring',
    gross: 'Samlet pris uden moms',
    vat: 'Moms ({{vat}}%)',
    total: 'Samlet pris'
  },
  parcelLocker: { cannotCancelMessage: 'Choosing delivery to a parcel locker can not be cancelled.' },
  cancelDeliveryTime: {
    cancelButton: 'Annuller Tidsvindue',
    preCancellationInfoText:
      'Hvis dette tidsvindue ikke passer dig, kan du annullere leveringstiden. Vi vender tilbage til dig med et nyt leveringstidspunkt.',
    postCancellationConfirmationText:
      'Du annullerede det foreslåede tidsvindue. Læn dig tilbage, så vender vi tilbage med et nyt tidsvindue.'
  },
  pickupPoint: {
    heading: 'Afhentningsadresse',
    termsLink: '<0>Se åbningstider</0>'
  }
}

export default da
