export const flexDeliveryChoicesStandard = [
  {
    txtId: 7,
    txtGroupKey: 'FLEX_DELIVERY',
    txtName: 'Front door',
    txtEn: 'By the frontdoor',
    txtNb: 'Ved inngangsdøren',
    txtSv: 'Vid ytterdörren',
    txtDk: 'Ved hoveddøren',
    txtFi: 'Ulko-ovelle',
    txtFr: "Devant la porte d'entrée"
  },
  {
    txtId: 8,
    txtGroupKey: 'FLEX_DELIVERY',
    txtName: 'Garage',
    txtEn: 'In the garage',
    txtNb: 'I garasjen',
    txtSv: 'I garaget',
    txtDk: 'I garagen',
    txtFi: 'Autotalliin',
    txtFr: 'Dans le garage'
  },
  {
    txtId: 9,
    txtGroupKey: 'FLEX_DELIVERY',
    txtName: 'Mailbox',
    txtEn: 'In the mailbox',
    txtNb: 'I postkassen',
    txtSv: 'I brevlådan',
    txtDk: 'I postkassen',
    txtFi: 'Postilaatikkoon',
    txtFr: 'Dans la boîte aux lettres'
  },
  {
    txtId: 11,
    txtGroupKey: 'FLEX_DELIVERY',
    txtName: 'Carport',
    txtEn: 'In the carport',
    txtNb: 'I carport',
    txtSv: 'I carporten',
    txtDk: 'I carporten',
    txtFi: 'Autokatokseen',
    txtFr: "Dans l'abri de jardin"
  },
  {
    txtId: 12,
    txtGroupKey: 'FLEX_DELIVERY',
    txtName: 'Terrace',
    txtEn: 'On the terrace',
    txtNb: 'På terrassen',
    txtSv: 'På terassen',
    txtDk: 'På terrassen',
    txtFi: 'Terassille',
    txtFr: 'Sur la terrasse'
  },
  {
    txtId: 140,
    txtGroupKey: 'FLEX_DELIVERY',
    txtName: 'Private parcel box',
    txtEn: 'In the private parcel box',
    txtNb: 'I privat pakkeboks',
    txtSv: 'I privat paketbox',
    txtDk: 'I privat pakkeboks',
    txtFi: 'Yksityinen pakettilaatikko',
    txtFr: 'Dans la caisse prévue à cet effet'
  }
]
