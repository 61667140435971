import React, { ReactNode } from 'react'

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode
}
export const MainContainer = ({ children, ...props }: Props) => (
  <main id="main-content" {...props}>
    {children}
  </main>
)
