import { Browser, getBrowserInfo } from '@glow/common/utils/browserUtil'
import { Banner } from '@glow/ui-components'
import i18next from 'i18next'
import React, { ReactNode, useState, useEffect } from 'react'

const allowedBrowsers: Browser[] = ['Chrome', 'Safari', 'Firefox']

export const BrowserBannerWrapper = (props: { children: ReactNode }) => {
  const [showBanner, setShowBanner] = useState(false)
  useEffect(() => {
    const browserInfo = getBrowserInfo()

    if (browserInfo === undefined || !allowedBrowsers.includes(browserInfo.name)) {
      setShowBanner(false)
    } else if (browserInfo.name === 'Safari' && browserInfo.version && browserInfo.version <= 15) {
      setShowBanner(true)
    } else if (browserInfo.name === 'Chrome' && browserInfo.version && browserInfo.version <= 92) {
      setShowBanner(true)
    }
  }, [])
  return (
    <div>
      {showBanner ? (
        <Banner closeable onClose={() => setShowBanner(false)}>
          {i18next.t('common.unsupportedBrowser')}
        </Banner>
      ) : null}
      {props.children}
    </div>
  )
}
