const nb = {
  common: {
    unknown: 'Ukjent',
    shipmentId: 'Sendingsnummer',
    senderRef: 'Avsenderreferanse',
    phone: 'Telefon:',
    yes: 'Ja',
    no: 'Nei',
    cancel: 'Avbryt',
    somethingWentWrongTryAgain: 'Noe gikk galt. Vennligst prøv igjen senere.',
    courier: 'Sjåfør',
    done: 'Avslutt',
    unsupportedBrowser: 'Oppdater nettleserversjonen for å få tilgang til alle funksjonene',
    islandDeliveryBanner:
      'Ditt postnummer er registrert som øy i våre systemer. Om leveringsadresse er på en øy, vil ordren leveres til fergekai på fastlandet. Kontakt oss på 22 03 00 00 ved spørsmål.',
    updatedInformation: 'Oppdatert informasjon',
    thanks: 'Takk!',
    edit: 'Endre',
    confirmation: 'Bekreftelse',
    required: 'obligatorisk'
  },
  application: {
    loading: 'Lastes, vennligst vent.'
  },
  feedbackBox: {
    title: 'Hva synes du',
    description:
      'Vi gjennomfører for øyeblikket en test der du som mottaker kan spore sendingene dine i sanntid når de er ute for levering. Vi er interessert i å høre din mening, <0>vennligst gi oss tilbakemelding via denne lenken</0>.'
  },
  skipToMainContent: 'Gå til hovedinnholdet',
  countries: { sweden: 'Sverige', norway: 'Norge', denmark: 'Danmark' },
  pageNotFound: {
    title: 'Fant ikke siden',
    message: 'Vi kunne dessverre ikke finne siden du leter etter',
    reloadPage: 'Prøv å laste inn siden på nytt'
  },
  menu: {
    shipmentTracking: 'Hvor er sendingen?',
    aboutShipment: 'Om sendingen',
    shipmentEvents: 'Hendelser'
  },
  logisticsProvider: { deliveredBy: 'Leveres av' },
  deliveredShipment: { goodsDelivered: 'Din ordre er levert!' },
  deviatedShipment: {
    goodsNotDelivered: 'Ordren ble ikke levert',
    lastEvent: 'Siste hendelse {{time}}',
    nextStep: {
      headline: 'Hva skjer nå?',
      newAttempt:
        'Vi vil forsøke å levere pakken hjem til deg neste virkedag. Du får en ny melding når den er klar til utkjøring.',
      returnToSender: 'I henhold til avsender sin bestilling til oss, vil pakken nå bli sendt i retur til avsender.',
      pickupPoint:
        'Pakken blir videresendt til ditt lokale hentested i løpet av neste virkedag. Du får en ny melding når den er klar til henting.',
      reachOut: 'Vi kontakter deg for å avtale nytt leveringsforsøk.',
      homeDelivery: 'Pakken vil bli forsøkt levert hjem til deg isteden.'
    }
  },
  shipmentTracking: {
    showMapButton: 'Følg leveransen live',
    map: 'Kart',
    estimatedDeliveryTime: 'Estimert leveringstidspunkt',
    deliveryTimeChanged: 'Ditt estimerte leveringstidspunkt er endret.',
    numberOfStopsLeft_one: '{{count}} stopp før vi er hos deg',
    numberOfStopsLeft_other: '{{count}} stopp før vi er hos deg',
    manyStopsLeft: 'Mer enn {{many}} stopp før vi er hos deg',
    nextStop: 'Du er neste stopp!'
  },
  aboutShipment: {
    plannedDeliveryTimeHeader: 'Estimert leveringstidspunkt for sendingen din fra {{customerName}}',
    header: 'Om sendingen din fra {{customerName}}',
    deliveryTime: 'Leveringstidspunkt',
    timeWindow: 'mellom {{timeWindow}}',
    deliveryHeader: 'Leveringsadresse og kontaktinformasjon'
  },
  shipmentEvents: { header: 'Det siste om sendingen din fra {{customerName}}' },
  imageAlt: {
    calendarClockIcon: 'Kalenderklokke',
    questionMarkIcon: 'Spørsmålstegn',
    checkIcon: 'Hake',
    thumbsUp: 'Tommel opp',
    sadComputer: 'Trist datamaskin',
    goodsOutsideDoor: 'Vare utenfor dør',
    image: 'Bilde'
  },
  error: {
    cannotLoadMap: 'Beklager, kartet kan ikke lastes nå',
    invalidDeliveryDate: 'Valgt tidspunkt er ikke lenger gyldig. Last inn siden på nytt og forsøk igjen.',
    title: 'Noe gikk galt!',
    errorClickToReload: 'Trykk her for å laste siden på nytt',
    phoneNumber: 'Ugyldig telefonnummer. Gyldig er: +47 12345678.',
    phoneNumberPrefix: 'Du må angi en landkode, f.eks. +47 ...',
    CONSIGNMENT_ALREADY_ASSIGNED_TO_SLOT: 'Noe gikk galt, vennligst last inn siden på nytt.',
    INVALID_COUNTRY_CODE:
      'Noe informasjon mangler om din leveranse. Hvis du fortsatt ønsker at den skal leveres i en pakkeboks, bør du ta kontakt med kundeservice.',
    INVALID_LOCATION:
      'Noe informasjon mangler om din leveranse. Hvis du fortsatt ønsker at den skal leveres i en pakkeboks, bør du ta kontakt med kundeservice.',
    INVALID_DELIVERY_CONTACT_INFO:
      'Noe informasjon mangler om din leveranse. Hvis du fortsatt ønsker at den skal leveres i en pakkeboks, bør du ta kontakt med kundeservice.',
    INVALID_DELIVERY_PHONE:
      'Noe informasjon mangler om din leveranse. Hvis du fortsatt ønsker at den skal leveres i en pakkeboks, bør du ta kontakt med kundeservice.',
    CANNOT_ASSIGN_PARCEL_LOCKER: 'Kunne ikke velge en luke i denne pakkeboksen. Vennligst prøv med en annen.',
    CANNOT_CANCEL_PARCEL_LOCKER_DELIVERY: 'Noe gikk galt, vennligst prøv igjen senere.'
  },
  event: {
    parcels: 'kolli',
    outOf: 'av',
    deviationHeadline: {
      'H-12': 'Vi kunne ikke levere sendingen din',
      'H-10': 'Vi fant ikke frem til adressen din',
      'H-13': 'Sendingen blir returnert',
      'V-41-K': 'Sendingen er dessverre forsinket',
      'V-DB-AB': 'Sendingen er dessverre forsinket',
      'H-BL-A': 'Vi kunne ikke nå avtalt leveringssted',
      'H-11-Z': 'Vi fant ikke frem til adressen din',
      'VI-16-X': 'Vi rakk ikke å levere sendingen din',
      'V-100-CA': 'Vi kunne ikke levere til pakkeboks',
      'V-100-CI': 'Vi kunne ikke levere til en pakkeboks',
      'V-80-CI': 'Vi kunne ikke levere til en pakkeboks',
      'V-99-CI': 'Vi kunne ikke levere til en pakkeboks',
      'V-AO-CI': 'Vi kunne ikke levere til en pakkeboks'
    },
    deviation: {
      'H-12': 'Dessverre traff vi ingen hjemme som kunne ta den imot',
      'H-11': 'Sendingen er forsøkt levert, men mottaker ble ikke funnet',
      'H-10': 'Vi kunne ikke levere sendingen din fordi vi manglet informasjon om adressen din',
      'H-13': 'Fordi du ikke ønsket å ta den imot',
      '7-10': 'Leveransen kunne ikke plukkes opp pga ufullstending adresse',
      '7-12': 'Avsenderen var ikke tilgjengelig. Leveransen har ikke blitt hentet',
      '7-82': 'Leveransen var ikke klar for å bli plukket opp',
      'V-41-K': 'Den blir videresendt så raskt det lar seg gjøre',
      'V-DB-AB': 'Den blir videresendt så raskt det lar seg gjøre',
      'H-07-R': 'Mislykket sosialkontroll',
      'H-07-Z': 'Mislykket sosialkontroll',
      'H-08-Z': 'Mislykket alderskontroll',
      'H-09-Z': 'Mislykket ID-kontroll',
      'VI-16-X':
        'I dag gikk ikke alt som det skulle, og vi rakk derfor ikke å levere sendingen din som avtalt. Vi beklager forsinkelsen',
      'H-BL-A': 'Derfor fikk vi dessverre ikke levert sendingen din dit du ba om det',
      'H-11-Z': 'En typisk årsak til dette er at navn eller nummer manglet ved døren',
      'V-100-CA': 'Det var ingen ledige pakkebokser i nærheten av deg',
      'V-100-CI': 'Ingen ledig luke',
      'V-80-CI': 'Pakken over tillat mål/vekt',
      'V-99-CI': 'Annet Ukjent',
      'V-AO-CI': 'Automaten er ute av drift'
    },
    message: {
      created: 'Registrert',
      delivered: 'Levert',
      collected: 'Opphentet',
      deviated: 'Avvik',
      'delivery-time-estimated': 'Oppdatert leveringstid',
      'delivery-time-purchased': 'Ny leveringstid kjøpt',
      'delivered-manually-overridden': 'Levert',
      'deviated-manually-overridden': 'Avvik',
      'collected-manually-overridden': 'Opphentet',
      'flex-delivery-ordered': 'Forenklet leveranse',
      flexDeliveryOrderedDescription: 'Forenklet leveranse bestilt av mottaker',
      'flex-delivery-ordered-by-sender': 'Forenklet leveranse bestilt av avsender',
      'flex-delivery-changed': 'Forenklet leveranse endret',
      'reported-damaged': 'Meldt skade',
      'reported-missing-article': 'Rapportert manglende produkt',
      'apartment-information-updated': 'Leilighetsinformasjon oppdatert',
      'claim-registered-email-sent': 'E-post om skadede- eller manglende varer sendt',
      'parcel-locker-delivery-selected': 'Pakkeboks valgt'
    }
  },
  date: { date: 'Dato', today: 'I dag', tomorrow: 'I morgen', yesterday: 'I går' },
  serviceUpsales: {
    heading: 'Vil du ha varene båret inn?',
    selectDateTime: 'Velg tid og dato for innbæring',
    confirm: 'Til betaling',
    curbsideServiceInfo: 'Med innbæring får du varene levert til et valgfritt sted innendørs.',
    orderService: 'Velg tid for innbæring (fra 399,-)',
    agreeToTerms: 'Ved å bestille godkjenner du <0>vilkårene for innbæring</0>.',
    noAvailableDeliveryTimesError: 'Something went wrong when fetching the time slots. Please reload the page.',
    termsLink: 'Se <0>vilkår for innbæring</0>.',
    terms: {
      mainTitle: 'Beskrivelse av tilleggstjenesten Indoor',
      mainIntroPara1:
        'Ved å bestille tilleggstjenesten innbæring, så vil våre sjåfører bære varene inn i boligen til en plass som du selv velger, uten at du behøver å hjelpe til.',
      mainIntroPara2:
        'Av sikkerhetsgrunner vil sjåførene ha vernesko på seg., Det er derfor viktig at du beskytter gulv og flytter unna møbler, inventar o.l. så det ikke er noen hindringer på veien dit du ønsker varene plassert. Våre sjåfører vil bære varene inn uten å ta de ut av emballasjen.',
      mainIntroPara3:
        'Det er en forutsetning for innbæring at det kan skje på en sikker , uhindret måte innenfor de grenser for mål og vekt som fremgår av våre vilkår.  Det er du som bestiller av tjenesten «Indoor» som er ansvarlig for fri adkomst og at mål og vekt er innenfor de grenser som fremgår av våre vilkår.',
      conditionsTitle: 'Vilkår for tilleggstjenesten Indoor',
      conditionsIntro:
        'Ved bestilling av tilleggstjenesten innbæring ("<strong>Indoor</strong>") av en standard tung hjemlevering ("<strong>Curbside</strong>") så inngår du som bestiller en avtale med Bring Home Delivery Norge AS, org.nr: 923 542 973 ("<strong>Bring</strong>") og aksepterer følgende vilkår:',
      bulletOne:
        'Bestilling betyr du gir Bring rett til å håndtere nødvendige personopplysninger for å utføre tjenesten for deg. Vi viser til Personvernerklæring for Posten-konsernet (<0>https://www.bring.no/privacy-policy</0>)',
      bulletTwo:
        'Betaling skal skje med Vipps eller bankkort. Transaksjonen mellom deg som bestiller og Bring håndteres av tredjepart',
      bulletThree: 'Bestillingen kan ikke angres etter at vi har lastet varene på bil',
      bulletFour:
        'Gjennom å betale anses du som bestiller å ha akseptert disse vilkårene. Disse vilkår er dermed akseptert på betalingstidspunktet.',
      delivery: {
        title: 'Levering',
        intro:
          'Hele sendingen, dvs. alle deler (kolli) i sendingen, bæres opp av Bring til én plassering, valgt av bestiller. Det vil si at hele sendingen leveres til en og samme plassering.',
        bulletOne:
          'Bestiller er ansvarlig for å forberede og sikre fri og uhindret adkomst til valgt leveringssted på adressen',
        bulletTwo: 'Bring sine ansatte vil beholde verneskoene på under hele leveransen.',
        bulletThree: 'Bestiller er ansvarlig for å sikre inventar, gulv og tepper mot skader',
        bulletFour:
          'Bestiller av tjenesten skal holde kjæledyr(hund, katt og andre dyr) i bånd eller bur og på trygg avstand fra våre sjåfører.',
        bulletFive:
          'Bring forbeholder seg retten til på fritt skjønn å vurdere om levering kan skje i henhold til vilkår, trygt og uten fare for skade på person eller eiendom.'
      },
      deviation: {
        title: 'Avvik og begrensninger',
        intro: 'Bring forbeholder seg retten til å avbryte innbæring hvis vilkår ikke er oppfylt;',
        bulletOne: 'Det ikke er fri adkomst til anvist plass.',
        bulletTwo:
          'Sendingen av Bring bedømmes til å kreve mer enn to personer for å kunne bæres inn uten risiko for person- eller eiendomsskade. (F.eks. på grunn av størrelse eller vekt).',
        paraOne:
          'Om innbæringen må avbrytes vil levering skje som avtalt med avsender. Det vil si fram til første hindring.',
        paraTwo:
          'Merk at tilleggstjenesten «Indoor» ikke kan bestilles for kolli med vekt som overstiger 70 kg, lengde kan maks være 240 cm, og lengde + omkrets maksimalt 640 cm.',
        paraThree:
          'Sjåføren har på grunn av risiko for skader ikke mulighet til å åpne emballasje før innbæring. Sjåføren har heller ikke mulighet til å ta med seg forpakning fra levert produkt, om ikke tilleggstjeneste for det er kjøpt.'
      },
      miscellaneous: {
        title: 'Øvrig',
        paraOne: 'Bestemmelsene i NSAB 2015 gjelder ved søksmål, skade, force majeure, se <0>bring.no/vilkar</0>',
        paraTwo:
          'For eventuelle spørsmål, kontakt Brings kundeservice på tlf: 40004911 eller epost: <0>homedelivery.norge@bring.com</0>'
      }
    }
  },
  housingInfo: {
    housingTypeTitle: 'Bor du i leilighet eller hus?',
    apartment: 'Leilighet',
    house: 'Hus',
    selectConfirmationApartment:
      'Takk for at du hjelper oss å levere raskere. Pass på at <strong>navnet ditt</strong> er synlig på døren eller postkassen.',
    selectConfirmationHouse:
      'Takk for at du hjelper oss å levere raskere. Pass på at <strong>husnummeret</strong> er synlig på huset eller postkassen.',
    apartmentInformation: 'Informasjon om leiligheten',
    gateCodeOrNameLabel: 'Navn eller nummer på ringeklokken',
    floorNumber: 'Etasje',
    elevator: 'Heis',
    selectOption: 'Velg alternativ',
    elevatorIsAvailable: 'Ja, heis er tilgjengelig',
    elevatorNotAvailable: 'Nei, heis er ikke tilgjengelig',
    submitError: 'Vennligst fyll inn riktig etasje',
    submit: 'Send inn',
    submitChange: 'Send inn Endringer',
    confirmationText: 'Dette vil hjelpe oss å levere raskere.',
    confirmationHeader: 'Takk for oppdateringen av leilighetsinformasjonen din.'
  },
  flex: {
    header: 'Skal vi sette leveransen utenfor døren din?',
    termsApproval: 'Ved å velge Ja aksepterer du våre <1>vilkår</1>.',
    privacyPolicy: 'Du finner vår <1>personvernerklæring</1> her.',
    deliverySpotHeader: 'Hvor skal vi sette leveransen?',
    deliverySpotDefaultOption: 'Velg sted',
    deliverySpotTextHeader: 'Melding til sjåføren (maks 50 tegn)',
    deliverySpotError: 'Velg hvor vi skal legge leveransen',
    confirmationHeader: 'Takk for at du oppdaterte leveringsinformasjonen din.',
    confirmationText:
      'Vi setter som avtalt varen din {{location}} og sender deg en bekreftelse. Sjåføren tar eventuelt bilde ved levering.',
    infoText:
      'Vi setter som avtalt varen din {{location}}. Vi varsler deg når leveringen er utført. Sjåføren tar eventuelt bilde ved levering.',
    notRingDoorBellText: 'Budet ringer ikke på.',
    submitChanges: 'Send inn endringer',
    submit: 'Send inn',
    terms: {
      header: 'Vilkår og betingelser',
      description: 'Levert utenfor døren - sånn fungerer det:',
      details1:
        'Som mottaker velger du hvor vi skal sette igjen varen og må påse at det er et passende sted. Husk at leveringsplassen må være både trygt og beskyttet fra været.',
      details2: 'Du vil motta en SMS som bekreftelse på at varene har blitt levert.',
      details3: 'Etter levering er du som mottaker ansvarlig for eventuelle skader eller tap.'
    },
    flexDeliveryCancelledInfo: 'Vi vil levere den til deg personlig.'
  },
  digitalIdentity: {
    cta: {
      submit: 'Send inn og signer',
      retry: 'Prøv å bekrefte på nytt',
      info: {
        header: 'Vil du ha en jevnere levering?',
        se: 'For å gjøre leveringen smidigere kan du identifisere deg med BankID før leveringstidspunktet',
        no: 'For å gjøre leveringen smidigere kan du identifisere deg med BankID før leveringstidspunktet',
        dk: 'For å gjøre leveringen smidigere kan du identifisere deg med MitID før leveringstidspunktet'
      },
      flex: {
        header: 'Identifikasjon kreves',
        se: 'Du må identifisere deg før vi kan plassere leveringen utenfor. Bruk knappen ovenfor for å bekrefte identiteten din med BankID.',
        no: 'Du må identifisere deg før vi kan plassere leveringen utenfor. Bruk knappen ovenfor for å bekrefte identiteten din med BankID.',
        dk: 'Du må identifisere deg før vi kan plassere leveringen utenfor. Bruk knappen ovenfor for å bekrefte identiteten din med MitID.'
      },
      button: { se: 'Signer med BankID', no: 'Signer med BankID', dk: 'Signer med MitID' }
    },
    verificationError: {
      header: 'Bekreftelse mislyktes',
      message:
        'Du kan alltid motta forsendelsen personlig ved å vise et gyldig identifikasjonsdokument når sjåføren kommer, eller du kan prøve å fullføre bekreftelsen på nytt'
    },
    verificationSuccess: {
      header: 'Identiteten din er bekreftet',
      saveCodeHeader: 'Identitetsverifisering ble utført!',
      saveCodeText:
        'Lagre denne koden og ta den med deg når du får pakken, eller send den til den som henter den. Du får en sms når budet er utenfor leveringsadressen. Denne koden sendes også som sms.',
      nextPageVisit: 'Din bekreftelseskode er sendt til deg på sms, gi koden til sjåføren ved levering.'
    },
    visibleDamageNotice:
      'Sørg for å sjekke varene for synlige skader før du godkjenner leveransen. Synlige skader skal registreres av sjåføren.'
  },
  damageInfo: {
    checkYourGoods: 'Husk å sjekke varene dine!',
    checkYourGoodsDescription: 'Er det noe som mangler eller er skadet? Klikk under for å rapportere inn dette',
    createReport: 'Start rapportering',
    reportDamage: 'Rapporter skade',
    reportMissingArticle: 'Rapporter manglende produkt',
    reportMissingArticleOrDamage: 'Rapporter manglende produkt eller skade',
    describeDamage: 'Beskriv skaden og skriv inn artikkelnummer',
    describeDamagePlaceholder: 'For eksempel: Skapdøren har en skade øverst til høyre. IKEA artikkelnummer: 12345678',
    uploadPhotoDamaged: 'Last opp bilde av skaden',
    uploadPhotoDamagedText: 'Klikk her eller dra og slipp',
    uploadPhotoDamagedDescription: 'Du kan maksimalt laste opp fire bilder',
    uploadMaximumFileSizeLimit: 'Filstørrelse kan ikke være mer enn {{size}} KB',
    submit: 'Send inn',
    uploadAllowedFormats: 'Godkjente filformater {{formats}}',
    unfinishedReport: 'Uferdig rapport',
    doYouWishToContinue: 'Endringer du har gjort vil ikke bli lagret. Ønsker du å fortsette?',
    stayOnPage: 'Bli på siden',
    continueWithoutSubmitting: 'Fortsett uten å lagre',
    missingDescription: 'Du må legge til beskrivelse',
    describeMissingArticle: 'Oppgi produktet som mangler',
    describeMissingArticlePlaceholder: 'For eksempel: IKEA artikkelnummer: 00000000',
    articleDescriptionError: 'Du må legge til artikkelnummer',
    confirmation: 'Bekreftelse',
    reportSubmittedPara1:
      'Tusen takk for at du tok deg tid til å registrere avvik på ordren din. Ta kontakt med IKEA på telefon 64 00 12 73, så skal vi hjelpe deg med en god løsning.',
    reportSubmittedPara2: 'Trykk avslutt for å gå tilbake til startsiden, eller for å lage en ny rapport.',
    minimumFileSizeLimit: 'Du må legge ved minst ett bilde'
  },
  plannedDelivery: {
    headline: {
      delivery: 'Estimert leveringstidspunkt for sendingen din fra {{customer}}',
      pickupPoint: 'Dine varer fra {{customer}} er booket for henting',
      pickup: 'Din levering fra {{customer}} er nå klar for opphenting. Planlagt tid for opphenting',
      return: 'Planlagt tid for opphenting av din retur til {{customer}}'
    },
    deliveryTimeInfo: 'Når vi begynner på ruten, vil du få et mer nøyaktig estimat på én time.',
    notAGoodTime: 'Passer ikke tiden?',
    changeTime: 'Endre dato og tid',
    newAttempt: 'Er du ikke hjemme til å ta imot pakken, forsøker vi igjen neste virkedag',
    pickupPoint: 'Er du ikke hjemme til å ta imot pakken, leverer vi den til ditt nærmeste hentested',
    returnToSender: 'Er du ikke hjemme til å ta imot pakken, vil pakken bli retunert til avsender',
    notAvailable: {
      hd: {
        headline: 'Noe har gått galt!',
        text: 'Vennligst kontakt vårt kundesenter for å bestille ny tid for din levering, se telefonnummer under.'
      }
    }
  },
  timeBooking: {
    confirm: 'Bekreft',
    selectDeliveryTime: 'Velg et leveringstidspunkt',
    header: 'Velg dato og tid',
    deliveryTime: { delivery: 'Leveringstidspunkt', pickup: 'Opphentingstid', return: 'Opphentingstid' },
    submit: {
      delivery: 'Bekreft leveringstidspunkt',
      pickup: 'Bekreft opphentingstid',
      return: 'Bekreft opphentingstid'
    },
    confirmation: {
      header: 'Bekreftelse',
      ready: 'Alt klart!',
      details: {
        delivery: 'Len deg tilbake – så tar vi hånd om resten. Vi kommer til deg:',
        pickup: 'Len deg tilbake - så tar vi hånd om resten. Du er velkommen til oss:',
        pickupPoint: 'Booket tid for henting',
        return: 'Len deg tilbake – så tar vi hånd om resten. Vi kommer til deg:'
      },
      edit: 'Endre'
    }
  },
  bookingNotPossible: {
    header: 'Åjsann!',
    details: {
      delivery:
        'Her har det skjedd en feil, og vi får dessverre ikke endret leveringsdag akkurat nå. Prøv igjen senere eller kontakt kundeservice på nummeret nedenfor.',
      pickup:
        'Det er ikke mulig å bestille opphentingstid på nettet akkurat nå. Prøv igjen om en stund eller ring vårt Contact Center på et av telefonnummerne under.',
      return:
        'Det er ikke mulig å bestille opphentingstid på nettet akkurat nå. Prøv igjen om en stund eller ring vårt Contact Center på et av telefonnummerne under.'
    },
    'details-saas': {
      delivery: 'Beklager, det er ikke mulig å bestille leveringstid online akkurat nå. Vennligst prøv igjen senere.',
      pickup: 'Hentetid kan ikke bestilles online akkurat nå. Vennligst prøv igjen om et øyeblikk.',
      return: 'Hentetid kan ikke bestilles online akkurat nå. Vennligst prøv igjen om et øyeblikk.'
    }
  },
  paymentFailed: {
    header: 'Betaling mislyktes',
    details: 'Vennligst prøv igjen senere, eller ring vår kundeservice på et av telefonnumrene nedenfor.',
    detailsSaas: 'Dessverre kunne vi ikke fullføre betalingen. Vennligst prøv igjen senere.',
    goBack: 'Gå tilbake til sporingssiden'
  },
  contact: {
    questions: 'Ved spørsmål',
    callUs: 'kan du ringe vår kundeservice.',
    contactUs: 'kan du kontakte oss på '
  },
  pickupTracking: { estimatedPickupTime: 'Estimert opphentingstidspunkt', goodsPickedUp: 'Varen er plukket opp!' },
  environmentFriendly: {
    fossilFreeDelivery1: 'Din leveranse er ',
    fossilFreeDelivery2: 'fossilfri.',
    zeroEmissionDelivery: 'Din leveranse blir gjort ',
    bicyclePreposition: 'med ',
    carPreposition: 'med ',
    electric: 'elbil.',
    bicycle: 'sykkel.',
    hydrogen: 'hydrogenbil.',
    bring: {
      electric: '100% elektrisk',
      bicycle: '100% muskelkraft',
      fossilFreeDelivery: '100% fossilfritt drivstoff',
      naturally: 'naturligvis'
    }
  },
  ikeaDeliveryNote: {
    doYouNeedADeliveryNote: 'Trenger du en følgeseddel fra IKEA?',
    noProblemDownloadItHere: 'Ikke noe problem! Du kan laste den ned her.',
    downloadDeliveryNote: 'Last ned følgeseddel',
    noProblemViewItHere: 'Ingen problem! Du kan se og skrive den ut her.',
    viewDeliveryNote: 'Se følgeseddel'
  },
  deliveryNote: {
    title: 'Følgeseddel',
    sender: 'Avsender',
    vatNumber: 'MVA-nummer',
    orderNumber: 'Ordernummer',
    receiver: 'Mottaker',
    phone: 'Telefon',
    shipmentId: 'Forsendelsesnummer',
    articles: 'Artikler',
    orderDate: 'Bestillingsdato',
    deliveryDate: 'Leveringsdato',
    deliveryTime: 'Leveringstid',
    printedDate: 'Utskriftsdato',
    receiversReference: 'Mottakers referanse',
    receiversReferenceNote: 'Bruk dette nummeret ved all kontakt med oss',
    articleNumber: 'Nummer',
    articleName: 'Navn',
    articlePrice: 'Enhetspris',
    articleQuantity: 'Antall',
    numberOfPackages: 'Antall pakker',
    totalWeight: 'Total vekt',
    totalVolume: 'Totalvolum',
    print: 'Skriv ut følgeseddel'
  },
  requestPhoneNumber: {
    header: 'SMS-varsler',
    help: 'Legg inn ditt nummber for å motta leveringsvarsler på SMS.',
    label: 'Ditt mobilnummer',
    save: 'Lagre nummer',
    done: 'Takk! Du vil nå motta leveringsvarsler på SMS.'
  },
  vasDescription: { header: 'Om sendingen din' },
  christmas: {
    title: 'Spre litt varme i hverdagen',
    description: 'Vi leverer gjerne til nærmeste Pakkeboks, din postkasse eller Post i Butikk.',
    thankYou: 'Tusen takk!',
    wishRegistered: 'Ditt ønske er registrert på ønskelisten'
  },
  altText: { previewImage: 'Forhåndsvis bildet' },
  parcelLocker: {
    heading: 'Skal vi levere pakken din i en pakkeboks?',
    additionalInfo: 'Velg ja for å se tilgjengelige pakkebokser.',
    additionalInfoWithParcelLockerSelected: 'Velg hvilken pakkeboks du vil at vi skal levere pakken i.',
    pickupLocation: 'Opphentingslokasjon',
    selectParcelBox: 'Velg pakkeboks',
    selectParcelBoxValidationError: 'Vennligst velg pakkeboks',
    sendIn: 'Send inn',
    deliverPackageTo: 'Vi leverer pakken din i',
    deliveredText: 'Pakken din har blitt levert i en pakkeboks',
    bannerHeading: 'Viktig informasjon',
    bannerDescription: 'Vennligst bruk Posten-appen til å låse opp pakkeboksen.',
    futureDeliveryText: 'Pakken din vil bli levert i en pakkeboks.',
    deliveryOrderedByReceiver: 'Levering til pakkeboks valgt av mottaker',
    nextStop: 'Din pakkeboks er neste stopp',
    manyStopsLeft: 'Mer enn {{many}} stopp igjen før vi leverer pakken din i pakkeboksen.',
    numberOfStopsLeft: '{{count}} stopp før vi leverer pakken din i pakkeboksen.',
    numberOfStopsLeft_plural: '{{count}} stopp før vi leverer pakken din i pakkeboksen.',
    cannotCancelMessage:
      'Hvis pakken ikke er mulig å levere i pakkeboks, så leveres pakken på hentested neste virkedag.'
  },
  cancelDeliveryTime: {
    cancelButton: 'Avbestille Tidsvindu',
    preCancellationInfoText:
      'Dersom dette tidsvinduet ikke passer kan du kansellere leveringstiden. Vi kommer tilbake til deg med ny leveringstid.',
    postCancellationConfirmationText:
      'Du kansellerte det foreslåtte tidsvinduet. Len deg tilbake, så kontakter vi deg med et nytt tidsvindu.'
  },
  pickupPoint: {
    heading: 'Henteadresse',
    termsLink: '<0>Se åpningstider</0>'
  }
}

export default nb
